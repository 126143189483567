import React, { useEffect, useState } from "react";
import moment from "moment";
import useToastify from "hooks/useToastify";
import { useApiMutation } from "hooks/useApiMutation";
import {
  refillRequestAction,
  visitDetailsAction,
} from "api/actions/VisitActions";
import CSSLoader from "components/cssLoader/CSSLoader";
import classNames from "classnames";
import useModal from "hooks/useModal";
import Images from "assets/images";
import { useApiQuery } from "hooks/useApiQuery";

function RefillPopup({ onClose, id, setRefillRequested }) {
  const [meds, setMeds] = useState([]);
  const { showAlert } = useToastify();

  const { loading, payload: visitDetails } = useApiQuery(
    visitDetailsAction(id),
    true
  );

  const { mutate: requestRefill, loading: requestingRefill } =
    useApiMutation(refillRequestAction);

  const onRefillClick = async () => {
    try {
      const body = {
        medications: meds
          ?.filter((med) => med?.selected)
          ?.map((med) => {
            return {
              name: med?.name,
              detail: med?.detail,
            };
          }),
      };
      const result = await requestRefill(visitDetails?.id ?? id, body);
      if (result && result?.error === false) {
        showAlert("Refill requested successfully.", "success");
        setRefillRequested(true);
        onClose();
      } else {
        showAlert(
          result.error ||
            result.payload.error ||
            result.payload.error_msg ||
            "Something went wrong",
          "error"
        );
        setRefillRequested(false);
      }
    } catch (error) {
      showAlert(error?.message ?? "Something went wrong", "error");
    }
  };

  useEffect(() => {
    setMeds(
      visitDetails?.diagnosis?.medications
        ?.filter((med) => med?.medication_type === "dosespot" && med?.active)
        ?.map((med) => {
          return {
            ...med,
            selected: false,
          };
        })
    );
  }, [visitDetails]);

  if (loading) {
    return (
      <div className="flex items-center justify-center w-full h-full py-20 overflow-auto bg-black bg-opacity-50">
        <div className="h-full max-w-4xl md:w-1/2 rounded-xl flex items-center">
          <div className="mx-6 px-6 py-8 w-full bg-white rounded-xl space-y-16">
            <div className="flex justify-center items-center">
              <img className="w-40" src={Images.appLoader} alt="Loading" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center w-full h-full py-20 overflow-auto bg-black bg-opacity-50">
      <div className="h-full max-w-4xl md:w-1/2 rounded-xl flex items-center">
        <div className="mx-6 px-6 py-8 w-full bg-white rounded-xl space-y-8">
          <div className="flex justify-between items-center">
            <p className="text-2xl font-medium">
              Which of the following medications do you wish to refill?
            </p>
            <img
              onClick={onClose}
              className="cursor-pointer w-10"
              src={Images.closeIcon}
              alt={"close icon"}
            />
          </div>

          {meds?.length > 0 ? (
            <div className="flex flex-col space-y-8">
              <div className="flex flex-col space-y-4">
                {meds?.map((med, index) => (
                  <div
                    key={index}
                    className="flex items-start space-x-4 cursor-pointer md:self-start"
                    onClick={() =>
                      setMeds((prev) =>
                        prev?.map((checkedMed, i) => {
                          if (i === index) {
                            return {
                              ...checkedMed,
                              selected: !checkedMed?.selected,
                            };
                          }
                          return checkedMed;
                        })
                      )
                    }
                  >
                    <img
                      className="object-contain w-8 h-8"
                      src={
                        med?.selected
                          ? Images.squareChecked
                          : Images.squareUnhecked
                      }
                      alt="Med selected"
                    />
                    <p className="text-xl font-medium leading-8 text-indigo">
                      {med?.name}
                    </p>
                  </div>
                ))}
              </div>
              <button
                className={`w-full py-3 text-orange border border-orange rounded-md flex items-center justify-center self-start ${
                  meds?.some((med) => med?.selected)
                    ? "cursor-pointer"
                    : "cursor-not-allowed"
                }`}
                disabled={!meds?.some((med) => med?.selected)}
                onClick={onRefillClick}
              >
                {requestingRefill ? (
                  <CSSLoader color={"orange"} className="w-8" />
                ) : null}
                <p className="font-bold md:text-xl ">Refill Request</p>
              </button>
            </div>
          ) : (
            <div className="flex items-center space-x-2">
              <img
                className="w-16 h-16"
                src={Images.exclaimationIcon}
                alt="checked"
              />
              <p className="text-xl font-medium text-indigo">
                No medications are available for refilling
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function RefillRequest({
  id,
  visitDetails,
  refillRequested,
  setRefillRequested,
  refillRequestSentAt,
  diagnosisSentAt,
  className,
}) {
  const { setShowModal } = useModal();
  const diagnosed_at = moment(
    visitDetails?.diagnosis?.diagnosed_at ?? diagnosisSentAt
  );
  const days_after_diagnosis = moment().diff(diagnosed_at, "days");
  const refillRequestSentAtDays = moment(refillRequestSentAt);
  const refillRequestSentAtDays2 = moment().diff(
    refillRequestSentAtDays,
    "days"
  );

  const { showAlert } = useToastify();
  async function onRefillClick() {
    if (
      refillRequestSentAtDays2 < 7 ||
      refillRequested ||
      days_after_diagnosis < 7
    ) {
      // setShowConfirm(true);
      const date = refillRequestSentAt
        ? moment(refillRequestSentAt)?.format("MMM DD, YYYY")
        : moment()?.format("MMM DD, YYYY");
      if (days_after_diagnosis < 7) {
        showAlert(
          "Refill requests may be submitted at least 7 days after being seen by your doctor",
          "error",
          5000
        );
      } else if (refillRequestSentAtDays2 < 7) {
        showAlert(
          `Last refill request sent on ${date}` ?? "Something went wrong",
          "error",
          5000
        );
      } else if (refillRequested) {
        showAlert(
          `Last refill request sent on ${moment()?.format("MMM DD, YYYY")}`,
          "error",
          5000
        );
      }
    } else {
      setShowModal({
        state: true,
        children: (_, onClose) => (
          <RefillPopup
            onClose={onClose}
            id={id}
            setRefillRequested={setRefillRequested}
          />
        ),
        data: null,
      });
    }
  }

  return (
    <div>
      <button
        className={classNames(
          "px-2 py-3 bg-opacity-100 text-white rounded-md flex items-center justify-center self-start md:px-5 md:py-3",
          className
        )}
        onClick={onRefillClick}
      >
        <p className="font-bold md:text-xl ">Refill Request</p>
      </button>
    </div>
  );
}

export default RefillRequest;

import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import RadioButtonCard from "components/radioButtonCard/RadioButtonCard";
import SearchInputWithChipCard from "components/searchInputWithChip/SearchInputWithChipCard";
import Spacer from "components/Spacer/Spacer";
import Card from "components/visitCommon/Card";
import StepScreenContainer from "components/visitCommon/StepScreenContainer";
import VisitSubQuestionSlideUpContainer from "components/visitSubQuestionSlideUp/VisitSubQuestionSlideUpContainer";
import { dermatology_sub_steps } from "helpers/enum";
import scrollToBottom from "helpers/scrollToBottom";
import useModal from "hooks/useModal";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import useVisitStepChange from "hooks/useVisitStepChange";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import usePatients from "hooks/visit/usePatients";
import AddMedicationModal from "./AddMedicationModal";

function Medications() {
  const { setShowModal } = useModal();
  const [selected, setSelected] = useState(null);
  const [selectedMedications, setSelectedMedications] = useState([]);

  const dispatch = useDispatch();
  const { patientsList, incompleteData, subSteps } = useDermatologyVisitFlow();
  const { rightButtonClickRef } = useVisitStepChange();
  rightButtonClickRef.current = onRightButtonClick;
  const currentScreenValueFromStore =
    subSteps[dermatology_sub_steps.medications].value;

  const { getCurrentPatient } = usePatients();
  const currentPatient = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );

  const { updateVisit, updatingVisit } = useUpdateDermatologyVisit();

  useEffect(() => {
    if (currentScreenValueFromStore?.length) {
      if (
        currentScreenValueFromStore?.includes("None") &&
        currentScreenValueFromStore?.length === 1
      ) {
        setSelected("No");
        setSelectedMedications([]);
      } else {
        setSelected("Yes");
        setSelectedMedications(currentScreenValueFromStore);
      }
    } else {
      if (
        currentPatient?.medications?.length > 0 &&
        selectedMedications?.length === 0
      ) {
        setSelected("Yes");
        setSelectedMedications(
          currentPatient?.medications
            ?.filter(
              (med) =>
                med?.medication_type === "non_dosespot" ||
                med?.medication_type === "self_reported_medication" ||
                med?.medication_type === "prescription"
            )
            ?.map((med) => {
              if (med?.medication_type === "non_dosespot") {
                return {
                  name: med?.drug_name,
                  dose: med?.details?.dose,
                  frequency: med?.details?.frequency,
                  per: med?.details?.per,
                  form: med?.details?.form,
                  other_form: med?.details?.other_form,
                };
              }

              if (med?.medication_type === "prescription") {
                return {
                  name: med?.drug_name,
                  dose: med?.details?.dose,
                  frequency: med?.details?.frequency,
                  per: med?.details?.per,
                  form: med?.details?.form,
                  other_form: med?.details?.other_form,
                  dosespot_medication_id: med?.details?.dosespot_medication_id,
                };
              }

              return {
                name: med?.drug_name,
                DispensableDrugId: med?.details?.dispensable_drug_id,
                RoutedDoseFormDrugId: med?.details?.routed_dose_form_drug_id,
                NDC: med?.details?.ndc,
                RxCUI: med?.details?.rx_cui || null,
                Strength: med?.details?.strength || null,
                IsObsolete: med?.details?.is_obsolete,
                dose: med?.details?.dose,
                frequency: med?.details?.frequency,
                per: med?.details?.per,
                form: med?.details?.form,
                other_form: med?.details?.other_form,
              };
            })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentScreenValueFromStore, currentPatient]);

  useEffect(() => {
    if (selected === "No") {
      dispatch(
        dermaVisitFlowReducers.medications({
          isValid: true,
        })
      );
    } else if (selected === "Yes") {
      scrollToBottom();
      if (selectedMedications?.length) {
        dispatch(
          dermaVisitFlowReducers.medications({
            isValid: true,
          })
        );
      } else {
        dispatch(
          dermaVisitFlowReducers.medications({
            isValid: false,
          })
        );
      }
    }
  }, [dispatch, selected, selectedMedications]);

  function onRightButtonClick(_, nextAnimation) {
    updateVisit({
      payload: {
        case: {
          id: incompleteData?.id,
          medications:
            selected === "Yes"
              ? selectedMedications?.map((el) => {
                  if (el?.DispensableDrugId) {
                    return {
                      name: el?.name,
                      DispensableDrugId: el?.DispensableDrugId,
                      RoutedDoseFormDrugId: el?.RoutedDoseFormDrugId,
                      NDC: el?.NDC,
                      RxCUI: el?.RxCUI || null,
                      Strength: el?.Strength || null,
                      IsObsolete: el?.IsObsolete,
                      dose: el?.dose,
                      frequency: el?.frequency,
                      per: el?.per,
                      form: el?.form,
                      other_form: el?.other_form,
                    };
                  }

                  if (el?.dosespot_medication_id) {
                    return {
                      name: el?.name,
                      dose: el?.dose,
                      frequency: el?.frequency,
                      per: el?.per,
                      form: el?.form,
                      other_form: el?.other_form,
                      dosespot_medication_id: el?.dosespot_medication_id,
                    };
                  }

                  return {
                    name: el?.name,
                    dose: el?.dose,
                    frequency: el?.frequency,
                    per: el?.per,
                    form: el?.form,
                    other_form: el?.other_form,
                  };
                })
              : [{ name: "None" }],
          dermatology_steps: incompleteData?.dermatology_steps,
        },
      },
      change_current_screen: true,
      screen_to_complete: dermatology_sub_steps.medications,
      callBack: () => {
        dispatch(
          dermaVisitFlowReducers.medications({
            value:
              selected === "Yes"
                ? selectedMedications?.map((el) => {
                    if (el?.DispensableDrugId) {
                      return {
                        name: el?.name,
                        DispensableDrugId: el?.DispensableDrugId,
                        RoutedDoseFormDrugId: el?.RoutedDoseFormDrugId,
                        NDC: el?.NDC,
                        RxCUI: el?.RxCUI || null,
                        Strength: el?.Strength || null,
                        IsObsolete: el?.IsObsolete,
                        dose: el?.dose,
                        frequency: el?.frequency,
                        per: el?.per,
                        form: el?.form,
                        other_form: el?.other_form,
                      };
                    }

                    if (el?.dosespot_medication_id) {
                      return {
                        name: el?.name,
                        dose: el?.dose,
                        frequency: el?.frequency,
                        per: el?.per,
                        form: el?.form,
                        other_form: el?.other_form,
                        dosespot_medication_id: el?.dosespot_medication_id,
                      };
                    }

                    return {
                      name: el?.name,
                      dose: el?.dose,
                      frequency: el?.frequency,
                      per: el?.per,
                      form: el?.form,
                      other_form: el?.other_form,
                    };
                  })
                : [{ name: "None" }],
          })
        );
        nextAnimation();
      },
    });
  }
  return (
    <StepScreenContainer align="start">
      <MedicalAssistant
        content={
          <p>
            List any medications you are currently taking and/or review your
            current medication list.
          </p>
        }
      />
      <Card>
        <RadioButtonCard
          loading={updatingVisit}
          options={["Yes", "No"]}
          selected={selected}
          onSelect={setSelected}
        />
      </Card>
      {selected === "Yes" ? (
        <VisitSubQuestionSlideUpContainer>
          <Spacer height={40} />
          <SearchInputWithChipCard
            inputLabel={"Search Medication"}
            onClickInput={() =>
              setShowModal({
                state: true,
                children: (_, onClose) => {
                  return (
                    <AddMedicationModal
                      onClose={onClose}
                      selected={selectedMedications}
                      setSelected={setSelectedMedications}
                    />
                  );
                },
                data: null,
              })
            }
            selected={selectedMedications}
            setSelected={setSelectedMedications}
          />
        </VisitSubQuestionSlideUpContainer>
      ) : null}
    </StepScreenContainer>
  );
}

export default Medications;

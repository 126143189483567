import React, { useCallback, useEffect, useRef, useState } from "react";
import { getCheckoutDetails } from "api/actions/VisitActions";
import { speciality } from "helpers/enum";
import { useApiQuery } from "hooks/useApiQuery";
import { useDermatologyVisitFlow } from "hooks/useVisitFlow";
import BottomFixedButton from "components/bottomFixedButton/BottomFixedButton";
import MedicalAssistant from "components/MedicalAssistant/MedicalAssistant";
import useDataFromUserDetails from "hooks/useDataFromUserDetails";
import SelectPaymentMethod from "../../common/SelectPaymentMethod";
import useModal from "hooks/useModal";
import InsuranceUpdateModal from "../../common/InsuranceUpdateModal";
import DermatologyVisitSummary from "./DermatologyVisitSummary";
import LoaderComponent from "components/loader/LoaderComponent";
import CardPayments from "components/payment/CardPayments";
import PromoCode from "../../common/PromoCode";
import useUpdateDermatologyVisit from "hooks/visit/useUpdateDermatologyVisit";
import { useHistory } from "react-router-dom";
import useVisitStepChange from "hooks/useVisitStepChange";
import { dermaVisitFlowReducers } from "reducers/dermatology/dermaVisitFlowReducer";
import { useDispatch } from "react-redux";
import { currentSpeciality, setUserDetails } from "reducers/currentUserReducer";
import usePatients from "hooks/visit/usePatients";
import { useCurrentUser } from "hooks/useCurrentUser";
import { CURRENT_SOURCE } from "helpers/currentSource";
import { checkInsuranceNetworkActionForDerm } from "api/actions/UserActions";
import { trackEvent } from "helpers/analytics";
import SimpleHeader from "components/header/SimpleHeader";
import NewProviderWithPercentage from "components/providerWithPercentage/NewProviderWithPercentage";
import HelpButton from "../../common/HelpButton";
import { checkoutDisclaimer } from "helpers/statics";
import { Icon } from "stories/Icons";
import { MdWarningAmber } from "react-icons/md";
import startCase from "lodash.startcase";
import { IoAlertCircleOutline } from "react-icons/io5";
import CaseImageUploadInfo from "components/imageUploadInfoModal/CaseImageUploadInfo";
import Images from "assets/images";
import {
  getCaseImagesPresignedPhotoUrlAction,
  imageUploadErrorReport,
  successImageUploadReport,
  uploadPhotoIdToS3Action,
} from "api/actions/ImageUploadActions";
import { useParameterizedQuery } from "react-fetching-library";
import { useApiMutation } from "hooks/useApiMutation";
import useToastify from "hooks/useToastify";
import { getUpdatedValues, handleImageChange } from "../photos/UploadPhotos";
import Button from "components/buttons/Button";
import useImageUpload from "hooks/visit/useImageUpload";

function DermatologyCheckout() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { setShowModal } = useModal();
  const { loading } = useVisitStepChange();
  const { getCurrentPatient } = usePatients();
  const { incompleteData, completedProgress, patientsList, paymentMethod } =
    useDermatologyVisitFlow();

  const [cards, setCards] = useState([]);
  const [allowPromoCode, setAllowPromoCode] = useState(true);
  const [promoCode, setPromoCode] = useState("");
  const [promoAmount, setPromoAmount] = useState(0);
  const [isPromoCodeApplied, setIsPromoCodeApplied] = useState(false);
  const [checkingCoupon, setCheckingCoupon] = useState(false);
  const [addingCard, setAddingCard] = useState(false);
  const [isCheckingPrice, setIsCheckingPrice] = useState(false);
  const [confirmPaymentMethod, setConfirmPaymentMethod] =
    useState(paymentMethod);
  const [cashPrice, setCashPrice] = useState(null);
  const [insuranceBreakDown, setInsuranceBreakDown] = useState(null);

  const { userDetails } = useCurrentUser();
  const { dermaDoctorDetails } = useDataFromUserDetails();
  const {
    loading: isGettingCheckoutDetails,
    query: callGetCheckoutDetails,
    payload: checkoutDetails,
  } = useApiQuery(
    getCheckoutDetails(incompleteData?.id, speciality.dermatology),
    false
  );

  const { loading: checkingInsuranceInNetwork, payload: insuranceInNetwork } =
    useApiQuery(
      checkInsuranceNetworkActionForDerm(
        speciality.dermatology,
        incompleteData?.patient_id,
        userDetails?.derm_physician?.provider_id
      ),
      true
    );

  const { updateVisit } = useUpdateDermatologyVisit();

  useEffect(() => {
    callGetCheckoutDetails();
  }, [callGetCheckoutDetails]);

  useEffect(() => {
    if (checkoutDetails) {
      if (checkoutDetails?.insurance) {
        setConfirmPaymentMethod("insurance");
      }
    }
  }, [checkoutDetails, setConfirmPaymentMethod]);

  useEffect(() => {
    setConfirmPaymentMethod(paymentMethod);
  }, [paymentMethod]);

  // INFO: Shitstorm
  const { showAlert } = useToastify();
  const { uploadImage } = useImageUpload();

  const imagePickerRef = useRef(null);

  const [disableReuploadImageBtn, setDisabledReuploadImageBtn] = useState(true);
  const [reuploadImages, setReuploadImages] = useState(
    incompleteData?.failed_images_names
      ?.filter((imageName) => imageName !== "identity_confirmation")
      ?.map((imageName) => {
        return { [imageName]: null };
      }) || []
  );
  const [reuploadImageSubmission, setReuploadImageSubmission] = useState(
    incompleteData?.failed_images_names
      ?.filter((imageName) => imageName !== "identity_confirmation")
      ?.map((imageName) => {
        return { [imageName]: false };
      })
  );
  const [reuploadLoading, setLoading] = useState(false);
  const [idProofLoading, setIdLoading] = useState(false);

  const [idImageData, setIdImageData] = useState(null);

  useEffect(() => {
    if (
      reuploadImages?.some(
        (reuploadImage) => !reuploadImage[Object.keys(reuploadImage)[0]]
      )
    ) {
      setDisabledReuploadImageBtn(true);
    } else {
      setDisabledReuploadImageBtn(false);
    }

    let newArr = [...reuploadImages];
    let newArrSubmission = [...reuploadImageSubmission];
    newArr.forEach((reuploadImage, index) => {
      if (reuploadImage[Object.keys(reuploadImage)[0]]) {
        newArrSubmission[index][Object.keys(reuploadImage)[0]] = true;
      } else {
        newArrSubmission[index][Object.keys(reuploadImage)[0]] = false;
      }
    });
    setReuploadImageSubmission(newArrSubmission);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reuploadImages]);

  const userAgent = window.navigator
    ? window.navigator.userAgent
    : navigator.userAgent;
  const userPlatform = userAgent.includes("iPhone")
    ? "ios-web"
    : userAgent.includes("Android")
    ? "android-web"
    : "web";

  const {
    query: getPresignedUrl,
    // loading: gettingPreSignedUrl,
  } = useParameterizedQuery(getCaseImagesPresignedPhotoUrlAction);

  const {
    mutate: uploadPhotoIdToS3,
    // loading: uploadingPhotoIdToS3,
  } = useApiMutation(uploadPhotoIdToS3Action);
  const { query: reportImageUploadFailure } = useParameterizedQuery(
    imageUploadErrorReport
  );

  const { query: successImageTracker } = useParameterizedQuery(
    successImageUploadReport
  );

  function handleIdImageChange(event) {
    const { target } = event;
    const { files } = target;

    if (files && files[0]) {
      let reader = new FileReader();
      reader.onload = (event) => {
        let file_object;
        file_object = {
          uri: files?.[0]?.name?.toString(),
          file: files[0],
          filename: files?.[0]?.name?.toString(),
          data: event?.target?.result,
          size: (files[0]?.size / 1000000).toFixed(2),
        };
        setIdImageData(file_object);
        trackEvent(`identity_image_size`, {
          size: file_object?.size + "mb",
        });
      };
      reader.readAsDataURL(files[0]);
    }
  }

  async function handleIdImageSubmit() {
    try {
      setIdLoading(true);
      const data = await uploadImage(idImageData);

      if (!data) {
        showAlert("Image Upload Failed - Please try again", "error");
        setIdImageData(null);
      } else {
        await successImageTracker({
          id: incompleteData?.id,
          payload: {
            uploaded_image: "identity_confirmation",
          },
        });
        updateVisit({
          payload: {
            case: {
              dermatology_steps: incompleteData?.dermatology_steps,
            },
          },
          change_current_screen: false,
          screen_to_complete: null,
          callBack: () => {
            showAlert("ID Proof submitted successfully", "success");
            dispatch(
              dermaVisitFlowReducers.identity_confirmation({
                value: idImageData,
              })
            );
          },
        });
      }
    } finally {
      setIdLoading(false);
    }
  }

  async function getPresignedUrlFunction({
    uri,
    file,
    imageType,
    extension,
    successCallback,
  }) {
    if (uri?.startsWith("http")) {
      successCallback(null);
      return true; // Returning true as the process is considered successful
    } else {
      try {
        setLoading(true);
        const presignedResult = await getPresignedUrl({
          caseId: incompleteData?.id,
          payload: {
            case: {
              image_type: imageType,
              file_extension: extension?.split("/")?.[1],
            },
          },
        });

        if (presignedResult) {
          try {
            const uploadPhotoIdToS3Result = await uploadPhotoIdToS3({
              endpoint: presignedResult?.payload?.presigned_url,
              payload: file,
            });

            if (uploadPhotoIdToS3Result) {
              if (uploadPhotoIdToS3Result.error) {
                // setImageUploadError(true);
                // error callback
                await reportImageUploadFailure({
                  patientId: incompleteData?.patient_id,
                  payload: {
                    upload_issue: {
                      case_id: incompleteData?.id,
                      image_type: imageType,
                      filename: presignedResult?.payload?.file_key,
                      file_size: file.size,
                      platform: userPlatform,
                      s3_response: uploadPhotoIdToS3Result.error,
                    },
                  },
                });
                showAlert("Image Upload Failed - Please try again", "error");
                setLoading(false);
              } else {
                await successImageTracker({
                  id: incompleteData?.id,
                  payload: {
                    uploaded_image: imageType,
                  },
                });
              }
              // setImageUploadError(false);
              successCallback({
                kind: imageType,
                filename: presignedResult?.payload?.file_key,
                preSignedImageUrl:
                  presignedResult?.payload?.presigned_url?.split("?")?.[0],
              });
              return true;
            } else {
              return false;
            }
          } catch (error) {
            setLoading(false);
            showAlert("Image Upload Failed - Please try again", "error");
            setReuploadImages(
              incompleteData?.failed_images_names
                ?.filter((imageName) => imageName !== "identity_confirmation")
                ?.map((imageName) => {
                  return { [imageName]: null };
                }) || []
            );
            await reportImageUploadFailure({
              patientId: incompleteData?.patient_id,
              payload: {
                upload_issue: {
                  image_type: imageType,
                  filename: presignedResult?.payload?.file_key,
                  file_size: file.size,
                  platform: userPlatform,
                  s3_response: error,
                },
              },
            });
            return false;
          }
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    }
  }

  const caseImageReuploadHandleChange = (e, imageName) => {
    handleImageChange(
      e,
      (val) => {
        let newArr = [...reuploadImages];
        newArr.map((item) => {
          if (Object.keys(item).indexOf(imageName) > -1) {
            return (item[imageName] = val);
          }
          return item;
        });
        setReuploadImages(newArr);
      },
      imageName
    );
  };

  const caseImageReuploadPopup = (keyType) => {
    setShowModal({
      state: true,
      children: (_, onClose) => (
        <CaseImageUploadInfo
          title={
            keyType.includes("close_up") ? "Close Up Photo" : "Far Away Photo"
          }
          image={
            keyType.includes("close_up")
              ? Images.caseCloseUpPhoto
              : Images.caseFarAwayPhoto
          }
          info={
            keyType.includes("close_up")
              ? "Make sure to take a photo from about 6 inches away [Accepted image formats: PNG, JPG, JPEG]."
              : "Make sure to take a photo from about 1 feet away [Accepted image formats: PNG, JPG, JPEG]."
          }
          onClose={onClose}
          handleChange={(e) => caseImageReuploadHandleChange(e, keyType)}
        />
      ),
    });
  };

  function updateVisitFuncion(values, afterUploadPayload) {
    if (values) {
      updateVisit({
        payload: {
          case: {
            dermatology_steps: incompleteData?.dermatology_steps,
          },
          id: incompleteData?.id,
          case_images: afterUploadPayload,
        },
        change_current_screen: false,
        screen_to_complete: null,
        callBack: (updatedPayload) => {
          dispatch(
            dermaVisitFlowReducers.upload_photos({
              value: getUpdatedValues(values, updatedPayload),
            })
          );
          showAlert("Missing image(s) submitted successfully", "success");
          setLoading(false);
        },
      });
    }
  }
  async function caseImageReuploadHandleSubmit(values) {
    let afterUploadPayload = [];
    function checkThirdSetPhotos() {
      if (values?.close_up_photo_3?.uri?.length) {
        getPresignedUrlFunction({
          uri: values?.close_up_photo_3?.uri,
          file: values?.close_up_photo_3?.file,
          imageType: "close_up_photo_3",
          extension: values?.close_up_photo_3?.file?.type,
          successCallback: (cp3) => {
            if (cp3) {
              afterUploadPayload.push(cp3);
            }
            if (values?.far_away_photo_3?.uri?.length) {
              getPresignedUrlFunction({
                uri: values?.far_away_photo_3?.uri,
                file: values?.far_away_photo_3?.file,
                imageType: "far_away_photo_3",
                extension: values?.far_away_photo_3?.file?.type,
                successCallback: (fp3) => {
                  if (fp3) {
                    afterUploadPayload.push(fp3);
                  }
                  updateVisitFuncion(values, afterUploadPayload);
                },
              });
            } else {
              updateVisitFuncion(values, afterUploadPayload);
            }
          },
        });
      } else if (values?.far_away_photo_3?.uri?.length) {
        getPresignedUrlFunction({
          uri: values?.far_away_photo_3?.uri,
          file: values?.far_away_photo_3?.file,
          imageType: "far_away_photo_3",
          extension: values?.far_away_photo_3?.file?.type,
          successCallback: (fp3) => {
            if (fp3) {
              afterUploadPayload.push(fp3);
            }
            updateVisitFuncion(values, afterUploadPayload);
          },
        });
      } else {
        updateVisitFuncion(values, afterUploadPayload);
      }
    }

    function checkSecondSetPhotos() {
      if (values?.close_up_photo_2?.uri?.length) {
        getPresignedUrlFunction({
          uri: values?.close_up_photo_2?.uri,
          file: values?.close_up_photo_2?.file,
          imageType: "close_up_photo_2",
          extension: values?.close_up_photo_2?.file?.type,
          successCallback: (cp2) => {
            if (cp2) {
              afterUploadPayload.push(cp2);
            }
            if (values?.far_away_photo_2?.uri?.length) {
              getPresignedUrlFunction({
                uri: values?.far_away_photo_2?.uri,
                file: values?.far_away_photo_2?.file,
                imageType: "far_away_photo_2",
                extension: values?.far_away_photo_2?.file?.type,
                successCallback: (fp2) => {
                  if (fp2) {
                    afterUploadPayload.push(fp2);
                  }
                  checkThirdSetPhotos();
                },
              });
            } else {
              checkThirdSetPhotos();
            }
          },
        });
      } else if (values?.far_away_photo_2?.uri?.length) {
        getPresignedUrlFunction({
          uri: values?.far_away_photo_2?.uri,
          file: values?.far_away_photo_2?.file,
          imageType: "far_away_photo_2",
          extension: values?.far_away_photo_2?.file?.type,
          successCallback: (fp2) => {
            if (fp2) {
              afterUploadPayload.push(fp2);
            }
            checkThirdSetPhotos();
          },
        });
      } else {
        checkThirdSetPhotos();
      }
    }

    if (values?.close_up_photo_1?.uri?.length) {
      getPresignedUrlFunction({
        uri: values?.close_up_photo_1?.uri,
        file: values?.close_up_photo_1?.file,
        imageType: "close_up_photo_1",
        extension: values?.close_up_photo_1?.file?.type,

        successCallback: (cp1) => {
          if (cp1) {
            afterUploadPayload.push(cp1);
          }
          if (values?.far_away_photo_1?.uri?.length) {
            getPresignedUrlFunction({
              uri: values?.far_away_photo_1?.uri,
              file: values?.far_away_photo_1?.file,
              imageType: "far_away_photo_1",
              extension: values?.far_away_photo_1?.file?.type,
              successCallback: (fp1) => {
                if (fp1) {
                  afterUploadPayload.push(fp1);
                }
                checkSecondSetPhotos();
              },
            });
          } else {
            checkSecondSetPhotos();
          }
        },
      });
    } else if (values?.far_away_photo_1?.uri?.length) {
      getPresignedUrlFunction({
        uri: values?.far_away_photo_1?.uri,
        file: values?.far_away_photo_1?.file,
        imageType: "far_away_photo_1",
        extension: values?.far_away_photo_1?.file?.type,
        successCallback: (fp1) => {
          if (fp1) {
            afterUploadPayload.push(fp1);
          }
          checkSecondSetPhotos();
        },
      });
    } else {
      checkSecondSetPhotos();
    }
  }

  const getFinalPrice = useCallback(() => {
    if (confirmPaymentMethod === "insurance") {
      return 249;
    } else if (confirmPaymentMethod === "cash") {
      if (promoAmount) {
        return Number(cashPrice) - Number(promoAmount);
      } else {
        return Number(cashPrice);
      }
    } else {
      return null;
    }
  }, [cashPrice, confirmPaymentMethod, promoAmount]);

  useEffect(() => {
    if (confirmPaymentMethod) {
      if (confirmPaymentMethod === "insurance") {
        setPromoAmount(0);
        setPromoCode("");
        setAllowPromoCode(false);
        setIsPromoCodeApplied(false);
      } else if (confirmPaymentMethod === "cash") {
        setAllowPromoCode(true);
      }
    } else {
      setPromoAmount(0);
      setPromoCode("");
      setAllowPromoCode(false);
      setIsPromoCodeApplied(false);
    }
  }, [confirmPaymentMethod, getFinalPrice]);

  function completeCheckout() {
    trackEvent("derma_complete_checkout");
    const currentPatient = {
      ...getCurrentPatient(incompleteData?.patient_id, patientsList),
    };
    updateVisit({
      payload: {
        case: {
          coupon_code:
            confirmPaymentMethod === "cash"
              ? isPromoCodeApplied
                ? promoCode
                : null
              : null,
          payment_method:
            confirmPaymentMethod === "cash"
              ? 0
              : confirmPaymentMethod === "insurance"
              ? 1
              : null,
          status: "complete_awaiting_diagnosis",
          submitting_source: CURRENT_SOURCE,
        },
      },
      change_current_screen: false,
      screen_to_complete: null,
      callBack: () => {
        dispatch(dermaVisitFlowReducers.reset());
        dispatch(currentSpeciality({ currentSpeciality: null }));
        dispatch(
          setUserDetails({
            userDetails: { ...userDetails, incomplete_visit_id: null },
          })
        );
        history.replace({
          pathname: "/visit/submitted",
          state: { speciality: speciality.dermatology, currentPatient },
        });
      },
    });
  }

  const brightPayInsurance =
    !getCurrentPatient(incompleteData?.patient_id, patientsList)?.insurance
      ?.payment_required && confirmPaymentMethod === "insurance";

  const currentInsurance = getCurrentPatient(
    incompleteData?.patient_id,
    patientsList
  );
  const insuranceSelected =
    currentInsurance?.insurance.insurance_company === "NAPHCARE INC"
      ? "NaphCare"
      : currentInsurance?.insurance.insurance_company;

  function isButtonValid() {
    if (
      !getCurrentPatient(incompleteData?.patient_id, patientsList)?.insurance
        ?.payment_required &&
      confirmPaymentMethod === "insurance"
    ) {
      if (
        currentInsurance?.insurance.insurance_company === "NAPHCARE INC" &&
        !incompleteData?.insurance_authorisation?.auth_id
      ) {
        return false;
      } else return true;
    }
    if (
      confirmPaymentMethod === "insurance" &&
      getCurrentPatient(incompleteData?.patient_id, patientsList)?.insurance
        ?.auth_id_required &&
      !incompleteData?.insurance_authorisation?.auth_id
    ) {
      return false;
    } else if (
      isCheckingPrice ||
      !confirmPaymentMethod ||
      checkingCoupon ||
      checkingInsuranceInNetwork
    ) {
      return false;
    } else {
      if (
        typeof getFinalPrice() === "number" &&
        getFinalPrice() === 0 &&
        cards?.length
      ) {
        return true;
      } else if (
        typeof getFinalPrice() === "number" &&
        getFinalPrice() > 0 &&
        cards?.length
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  if (isGettingCheckoutDetails || loading) {
    return (
      <LoaderComponent
        showLoader={{
          state: true,
          message: loading
            ? "Submitting your visit. Do not press the refresh (or) go back button"
            : "Please wait",
        }}
      />
    );
  }

  return (
    <div className="relative h-screen overflow-hidden bg-white">
      <SimpleHeader
        title="Appointment Details"
        showBackButton={!loading}
        wrapper
      />
      <NewProviderWithPercentage
        doctorDetails={dermaDoctorDetails}
        percentage={completedProgress}
        className="border-b-2"
      />
      <div className="overflow-y-auto w-full bg-backgroundWhite h-eliminateHeaderWithPercentageViewAndFooter py-8">
        <div className="wrapper-lg">
          <MedicalAssistant
            xOffset={23}
            content={
              <p>
                Yay! Your visit is almost complete, please select a payment
                method and submit the visit.
              </p>
            }
          />
          {incompleteData?.failed_images_names?.length > 0 && (
            <div className="space-y-4 mx-8 my-4 p-4 w-5/8 rounded-md bg-yellow-200 md:mx-0 md:w-1/2">
              <p className="font-bold text-2xl flex items-center gap-3">
                <IoAlertCircleOutline /> OOPS! Your photos didn't upload. Select
                link(s) below to re-upload images again to submit your visit.
              </p>
              <div>
                <div className="flex flex-col">
                  {incompleteData?.failed_images_names
                    ?.filter((el) => el !== "identity_confirmation")
                    ?.map((e, index) => (
                      <div
                        key={index}
                        className="py-2 flex justify-between items-center cursor-pointer hover:underline"
                        onClick={() => caseImageReuploadPopup(e)}
                      >
                        <p className="text-xl font-semibold">
                          {index + 1}. {startCase(e)}
                        </p>
                        <p class="font-semibold">
                          {reuploadImageSubmission.reduce(
                            (acc, curr) => Object.assign(acc, curr),
                            {}
                          )[e]
                            ? "Completed"
                            : "Click here"}
                        </p>
                      </div>
                    ))}
                </div>
                {incompleteData?.failed_images_names?.filter(
                  (el) => el !== "identity_confirmation"
                )?.length > 0 && (
                  <Button
                    textSize="text-2xl"
                    className="px-4 py-2"
                    disabled={disableReuploadImageBtn}
                    loading={reuploadLoading}
                    onClick={() =>
                      caseImageReuploadHandleSubmit(
                        reuploadImages.reduce(
                          (acc, curr) => Object.assign(acc, curr),
                          {}
                        )
                      )
                    }
                    label="Submit Photos"
                  />
                )}
              </div>
              {incompleteData?.failed_images_names?.includes(
                "identity_confirmation"
              ) ? (
                <div className="flex flex-col">
                  <input
                    ref={imagePickerRef}
                    id="confirm_identity_image_picker"
                    type="file"
                    accept="image/png, image/jpg, image/jpeg"
                    onChange={handleIdImageChange}
                    className="hidden"
                  />
                  <label
                    htmlFor="confirm_identity_image_picker"
                    className="py-2 flex justify-between items-center cursor-pointer hover:underline"
                  >
                    <p className="text-xl font-semibold">
                      {incompleteData?.failed_images_names?.length}.{" "}
                      {startCase("identity_confirmation")}
                    </p>
                    <p class="font-semibold">
                      {idImageData ? "Completed" : "Click here"}
                    </p>
                  </label>
                  <Button
                    textSize="text-2xl"
                    className="px-4 py-2"
                    disabled={!idImageData}
                    loading={idProofLoading}
                    onClick={handleIdImageSubmit}
                    label="Submit ID Proof"
                  />
                </div>
              ) : null}
            </div>
          )}
          <div className="flex flex-col md:flex-row md:space-x-8 py-8">
            <div className="flex-1 px-8 md:px-0">
              <SelectPaymentMethod
                checkoutDetails={checkoutDetails}
                cashPrice={cashPrice}
                setCashPrice={setCashPrice}
                setIsCheckingPrice={setIsCheckingPrice}
                insuranceBreakDown={insuranceBreakDown}
                setInsuranceBreakDown={setInsuranceBreakDown}
                paymentMethod={confirmPaymentMethod}
                setPaymentMethod={setConfirmPaymentMethod}
                onUpdatePress={(insurance, currentPatient, callBack) => {
                  setShowModal({
                    state: true,
                    children: (_, onClose) => (
                      <InsuranceUpdateModal
                        onClose={onClose}
                        insuranceDetails={insurance}
                        currentPatient={currentPatient}
                        currentSpeciality={speciality.dermatology}
                        setInsurancePay={async () => {
                          await updateVisit({
                            payload: {
                              case: {
                                id: incompleteData?.id,
                                payment_method: 1,
                                dermatology_steps:
                                  incompleteData?.dermatology_steps,
                              },
                            },
                            change_current_screen: false,
                            screen_to_complete: null,
                            callBack: () => {
                              dispatch(
                                dermaVisitFlowReducers.setPaymentMethod({
                                  value: 1,
                                })
                              );
                            },
                          });
                        }}
                      />
                    ),
                    data: null,
                  });
                }}
                currentSpeciality={speciality.dermatology}
                patientsList={patientsList}
                incompleteData={incompleteData}
                checkingInsuranceInNetwork={checkingInsuranceInNetwork}
                insuranceInNetwork={insuranceInNetwork}
              />
            </div>
            <div className="flex-1 space-y-8 py-8 md:py-0">
              {brightPayInsurance && (
                <p className="text-yellow-500 text-2xl font-medium -mb-5 ml-1 flex items-center self-center gap-3">
                  <MdWarningAmber size={14} />
                  Payment method is not required at this time for{" "}
                  {insuranceSelected}
                </p>
              )}
              <div className="divide-y px-8 bg-white md:rounded-xl border relative">
                {brightPayInsurance && (
                  <div className="absolute inset-0 z-10 bg-gray-200 opacity-50 cursor-not-allowed" />
                )}
                <CardPayments
                  amount={Math.max(getFinalPrice()).toFixed(2)}
                  setAddingCard={setAddingCard}
                  cards={cards}
                  setCards={setCards}
                  caseId={incompleteData?.id}
                />
                {allowPromoCode && Number(cashPrice) > 0 ? (
                  <div className="py-8">
                    <PromoCode
                      promoAmount={promoAmount}
                      amount={cashPrice}
                      promoCode={promoCode}
                      setPromoCode={setPromoCode}
                      setPromoAmount={setPromoAmount}
                      isPromoCodeApplied={isPromoCodeApplied}
                      setIsPromoCodeApplied={setIsPromoCodeApplied}
                      setCheckingCoupon={setCheckingCoupon}
                      speciality={speciality.dermatology}
                    />
                  </div>
                ) : null}
              </div>
              <DermatologyVisitSummary
                data={incompleteData}
                className="bg-white md:rounded-xl border"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row text-center  md:space-x-10 py-10 md:py-20 max-w-7xl mx-auto w-full px-16 space-y-8 md:space-y-0 md:px-0">
          {checkoutDisclaimer.map((e) => (
            <div className="flex flex-col items-center" key={e.title}>
              <Icon name={e.icon} />
              <h3 className="text-themeBlack font-medium text-3xl pt-1 pb-3">
                {e.title}
              </h3>
              <p className="text-grey-150 text-lg font-medium">"{e.text}"</p>
            </div>
          ))}
        </div>
      </div>
      <HelpButton bottomClass={"bottom-40"} />
      <BottomFixedButton
        onClick={completeCheckout}
        loading={loading || addingCard || checkingInsuranceInNetwork}
        label={addingCard ? "Updating payment" : "Submit for Evaluation"}
        disabled={isButtonValid() ? false : true}
        bgColor="bg-white"
      />
    </div>
  );
}

export default DermatologyCheckout;

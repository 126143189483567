import ApiConfig from "api/config";

const myVisistsAction = {
  method: "GET",
  endpoint: ApiConfig.urls.visit.myVisists + ".json",
};
const myVisitsAction = {
  method: "GET",
  endpoint: ApiConfig.urls.visit.myVisits + ".json",
};

const visitDetailsAction = (visitId) => ({
  method: "GET",
  endpoint: `${ApiConfig.urls.visit.myVisists}/${visitId}.json`,
});

const requestReceiptAction = (visitId) => ({
  method: "POST",
  endpoint: `${ApiConfig.urls.visit.myVisists}/${visitId}/request-patient-receipt.json`,
});

const markConversationRead = (visitId) => ({
  method: "POST",
  endpoint: `${ApiConfig.urls.visit.myVisists}/${visitId}/conversations/mark-seen.json`,
});

const incompletVisitAction = (speciality) => ({
  method: "GET",
  endpoint: `${ApiConfig.urls.visit.myVisists}/incomplete/${speciality}.json`,
});

const createCase = (payload) => ({
  method: "POST",
  endpoint: ApiConfig.urls.visit.createVisit,
  body: payload,
});

const updateCase = ({ caseId, payload }) => ({
  method: "PUT",
  endpoint: `${ApiConfig.urls.visit.updateVisit}/${caseId}.json`,
  body: payload,
});

const confirmIdentity = ({ payload }) => ({
  method: "POST",
  endpoint: ApiConfig.urls.visit.createIdentity,
  body: payload,
});

const textUploadPhotoToMobileAction = ({ body }) => ({
  method: "POST",
  endpoint: ApiConfig.urls.visit.sendTextSmsForUploadPhoto,
  body,
});

const checkInsurance = ({ userId, payload, currentSpeciality }) => ({
  method: "POST",
  endpoint: `${ApiConfig.urls.visit.checkInsurance}/${userId}/check-insurance-native-new-pricing.json?speciality=${currentSpeciality}`,
  body: payload,
});

const getCheckoutDetails = (caseId, currentSpeciality) => ({
  method: "GET",
  endpoint: `${ApiConfig.urls.visit.myVisists}/${caseId}/checkout-details-new-pricing.json?speciality=${currentSpeciality}`,
});

const getCosmeticCheckoutDetails = (caseId) => ({
  method: "GET",
  endpoint: `${ApiConfig.urls.visit.myVisists}/${caseId}/checkout-details-cosmetic.json`,
});

const checkCoupon = ({ couponCode, speciality }) => ({
  method: "GET",
  endpoint: `${ApiConfig.urls.visit.coupon}${couponCode}.json?speciality=${speciality}`,
});

const linkInsuranceAction = ({ formData, accountId }) => ({
  method: "POST",
  endpoint: `${ApiConfig.urls.visit.linkInsurance}/${accountId}/link-insurance-native.json`,
  body: formData,
});

const getConsentAction = (visitId) => ({
  method: "GET",
  endpoint: `${ApiConfig.urls.visit.myVisists}/${visitId}/consent.json`,
});

const getCashPrice = (patientId, currentSpeciality) => ({
  method: "GET",
  endpoint: `${ApiConfig.urls.visit.cashPrice}/cash-price.json?patient_id=${patientId}&speciality=${currentSpeciality}`,
});

const postConsentAction = (visitId) => ({
  method: "POST",
  endpoint: `${ApiConfig.urls.visit.myVisists}/${visitId}/consent.json`,
});

const unservicedStateNotifyAction = ({ speciality }) => ({
  method: "POST",
  endpoint: ApiConfig.urls.visit.unservicedStateNotify(speciality),
});

const getBookingSlotsAction = ({ caseId }) => ({
  method: "GET",
  endpoint: ApiConfig.urls.visit.getBookingSlots(caseId),
});
const getBookingSlotsWithProvidersAction = ({ caseId }) => ({
  method: "GET",
  endpoint: ApiConfig.urls.visit.getBookingSlotsWithProviders(caseId),
});
const bookSlotAction = ({ caseId, payload }) => ({
  method: "POST",
  endpoint: ApiConfig.urls.visit.bookingASlot(caseId),
  body: payload,
});

const getPCSymptoms = ({ gender }) => ({
  method: "GET",
  endpoint: ApiConfig.urls.visit.getPCSymptoms(gender),
});

const discardVisit = (caseId) => ({
  method: "POST",
  endpoint: ApiConfig.urls.visit.discardVisit(caseId),
});

const visitConcerns = (speciality) => ({
  method: "GET",
  endpoint: ApiConfig.urls.visit.visitConcerns(speciality),
});

const getAudioRecordUrlAction = ({ caseId }) => ({
  method: "GET",
  endpoint: ApiConfig.urls.visit.uploadAudioRecord(caseId),
});

const getPopupInfo = (currentScreen) => ({
  method: "GET",
  endpoint: ApiConfig.urls.visit.popupInfo(currentScreen),
});

const postVisitSurveyAction = (payload) => ({
  method: "POST",
  endpoint: ApiConfig.urls.visit.visitSurvey(payload?.token),
  body: payload,
});

const refillRequestAction = (caseId, payload) => ({
  method: "POST",
  endpoint: ApiConfig.urls.visit.refillRequest(caseId),
  body: payload,
});

const patientVisittedVisitDetailsScreenAction = (caseId) => ({
  method: "POST",
  endpoint: ApiConfig.urls.visit.patientVisittedVisitDetailsScreen(caseId),
});

const getCosmeticConcernAction = (caseId) => ({
  method: "GET",
  endpoint: ApiConfig.urls.visit.getCosmeticConcerns(caseId),
});

const getRevisedInsuranceAction = (caseId) => ({
  method: "GET",
  endpoint: ApiConfig.urls.visit.revisedInsurance(caseId),
});

const acceptRevisedInsuranceAction = (token) => ({
  method: "GET",
  endpoint: ApiConfig.urls.visit.acceptRevisedInsurance(token),
});

const changeShippingAddress = ({ account_id, patient_id, payload }) => ({
  method: "PUT",
  endpoint: ApiConfig.urls.visit.changeShippingAddress(account_id, patient_id),
  body: payload,
});

const placeOtcOrder = (payload) => ({
  method: "POST",
  endpoint: ApiConfig.urls.visit.placeOtcOrder,
  body: payload,
});

const retryOtcOrderPayment = (payload) => ({
  method: "PUT",
  endpoint: ApiConfig.urls.visit.retryOtcOrderPayment,
  body: payload,
});

const trackSummaryViewsAction = ({ visitId, payload }) => ({
  method: "PUT",
  endpoint: ApiConfig.urls.visit.trackSummaryViews(visitId),
  body: payload,
});

const getVisitTerms = (speciality) => ({
  method: "GET",
  endpoint: `${ApiConfig.urls.visit.getTerms(speciality)}`,
});

const getVisitSymptomsAction = ({ speciality, gender }) => ({
  method: "GET",
  endpoint: `${ApiConfig.urls.visit.getVisitSymptoms(speciality, gender)}`,
});

const patientListDescriptionsAction = ({ listType }) => ({
  method: "GET",
  endpoint: `${ApiConfig.urls.visit.patientListDescriptions(listType)}`,
});

const getPreselectedInfoAction = {
  method: "GET",
  endpoint: ApiConfig.urls.visit.preselectedInfo,
};

const postPreselectedInfoAction = (payload) => ({
  method: "POST",
  endpoint: ApiConfig.urls.visit.preselectedInfo,
  body: payload,
});

export {
  myVisistsAction,
  myVisitsAction,
  incompletVisitAction,
  visitDetailsAction,
  requestReceiptAction,
  markConversationRead,
  createCase,
  updateCase,
  confirmIdentity,
  textUploadPhotoToMobileAction,
  checkInsurance,
  getCheckoutDetails,
  getCosmeticCheckoutDetails,
  checkCoupon,
  linkInsuranceAction,
  getConsentAction,
  postConsentAction,
  getCashPrice,
  unservicedStateNotifyAction,
  getBookingSlotsAction,
  getBookingSlotsWithProvidersAction,
  bookSlotAction,
  getPCSymptoms,
  discardVisit,
  visitConcerns,
  getAudioRecordUrlAction,
  getPopupInfo,
  postVisitSurveyAction,
  refillRequestAction,
  patientVisittedVisitDetailsScreenAction,
  getCosmeticConcernAction,
  getRevisedInsuranceAction,
  acceptRevisedInsuranceAction,
  changeShippingAddress,
  placeOtcOrder,
  retryOtcOrderPayment,
  trackSummaryViewsAction,
  getVisitTerms,
  getVisitSymptomsAction,
  patientListDescriptionsAction,
  getPreselectedInfoAction,
  postPreselectedInfoAction,
};
